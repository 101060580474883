// Charity > Logo

.cropper-crop-box {
	background-color: #fff;
}

// Charity > Campaign

.sample-image {
	transition: $transition-base;
	border: 2px solid $navy-10;

	&:hover {
		border: 2px solid $navy;		
	}
}

.sample-container {
	position: relative;

	a {
		img {
			transition: $transition-base;
		}

		.fa-layers {
			top: 50%;
			left: 50%;
			z-index: 2;
			max-width: 30%;
			position: absolute;
			transition: $transition-base;
			transform: translateX(-50%) translateY(-50%);

			// Override .icon-block (but class still needed for children)
			padding: 0;
			height: 100%;
		}

		&:hover {
			img {
				opacity: .8;
			}
			.fa-layers {
				max-width: 35%;
				filter: drop-shadow($box-shadow-lg);
			}
		}
	}
}

// Charity > Donation Reasons

.reason-prefix {
	.input-group-text:not(:empty) {
		width: 100%;
		white-space: unset;
		margin-bottom: -1px;
		border-radius: $input-border-radius $input-border-radius 0 0 !important;

		+ .form-control {
			border-radius: 0 0 $input-border-radius $input-border-radius !important;
		}
	}

	.input-group-text:empty {
		display: none;

		+ .form-control {
			border-radius: $input-border-radius !important;
		}
	}
}

.donation-reason {
	font-style: italic;
}

// Finance > CPA

.cpa-box {	
	max-height: 340px;
	overflow-y: scroll;
	word-break: keep-all;
	white-space: pre-wrap;
	font-family: var(--font-sans-serif);
}

// Toolkit

.input-invisible {
	height: 0;
	opacity: 0;
	width: 100%;
	pointer-events: none;
}

.input-code {
	overflow: scroll;
	font-size: .625em;
	font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

// Remove padding from nested box__contents (bit of a hack, due to complexity of grouped/single assets)
.toolkit-content .box__content > .box__content {
	padding: 0;
}

// Badge sticker (currently only used on donate-button-incentive page)
.badge-sticker {
	left: 0;
    top: 2rem;
    padding: 2ch;
    display: flex;
	aspect-ratio: 1;
	border-radius: 50%;
    text-align: center;
    position: absolute;
    align-items: center;
    justify-content: center;
    box-shadow: $box-shadow;
    transform: translateX(-25%) rotate(-10deg);
}
.badge-sticker--top {
	top: 2rem;
	bottom: unset;
}

.pointer-events-none {
	pointer-events: none 
}